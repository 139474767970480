import React from 'react'
import {graphql, useStaticQuery, Link} from "gatsby"

const SL = ({styles}) => {
	const query = useStaticQuery(graphql`
		query {
			allStrapiServices(limit: 3) {
			    edges {
			      node {
			        slug
			        name
			        excerpt
			        id
			        feature_image {
			          childImageSharp {
			            fluid {
			              src
			            }
			          }
			        }
			      }
			    }
			}
		}
	`)
	let d = [];
	query.allStrapiServices.edges.forEach(({node})=>{
		d.push(node);
	})
	return (
		<div className={`${styles.thumbnailsContainerSM} ${styles.mt_Minus3Rem} ${styles.thumbnailsContainerXS} ${styles.mb_6remSM} ${styles.mb_6remXS}`}>
			{
				d.map((item, index)=>{
					return (
						<div key={`s_${index}`} className={`${styles.flexVertical} ${styles.alignItemsCenter} ${styles.mb_2remXS} ${styles.mb_5remSM}`}>
							<span className={`${styles.overlayTarget} ${styles.mb_10px}`}>
								<img src={`${item.feature_image.childImageSharp.fluid.src}`} alt="Mindfulness Meditation"/>
								<div className={`${styles.overlayTintLayerSM}`}></div>
								<div className={`${styles.overlay} ${styles.c_white} ${styles.txtAlignCenter}`}>
									<h3><Link to={`/treatment-services/${item.slug}`}>{item.name}</Link></h3>
								</div>
								<div className={`${styles.textBelowTarget} ${styles.txtAlignCenter} ${styles.hideUnderXS} ${styles.maxWidth90Percent}`}>
									<p>{item.excerpt.substring(0, 100)} {item.excerpt.length > 100 ? '...' : ''}</p>
								</div>
							</span>
						</div>
					)
				})
			}
		</div>
	)
}
export default SL