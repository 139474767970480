import React from 'react';
import {graphql, useStaticQuery} from "gatsby"

const M = ({styles}) => {
	const query = useStaticQuery(graphql`
		query {
			allStrapiPages(filter: {slug: {eq: "Hi-I-m-Harmonie"}}) {
			    edges {
			      node {
			        excerpt
			        name
			        slug
			        feature_image {
			          childImageSharp {
			            fluid {
			              src
			            }
			          }
			        }
			      }
			    }
			}
		}
	`)
	let d = [];
	query.allStrapiPages.edges.forEach(({node})=>{
		d.push(node);
	})
	d = d[0]
	return(
		<div className={`${styles.flexUnderXS} ${styles.flexDirectionReverseCol} ${styles.alignItemsCenterXS} ${styles.grid2ColSM} ${styles.mb_10remSM} ${styles.mb_6remXS}`}>
			<div className={styles.txtAlignCenter}>
				<img src={d.feature_image.childImageSharp.fluid.src} alt="" className={`${styles.maxWidth80PercentXS} ${styles.mw_100PercentSM}`}/>
			</div>
			<div className={`${styles.maxWidth80PercentXS} ${styles.marginAutoSM} ${styles.mb_1remXS}`}>
				<h2 className={`${styles.c_mauve} ${styles.mb_2rem}`}>
					{d.name}
				</h2>
				<p className={styles.mb_1remXS}>
					{d.excerpt}
				</p>
			</div>
		</div>
	)
}

export default M