import React from 'react';
import {graphql, useStaticQuery, Link} from "gatsby"

const Q = ({styles}) => {
	const query = useStaticQuery(graphql`
		query {
			allStrapiPages(filter: {slug: {eq: "still-unsure-about-the-effectiveness-of-the-treatments."}}){
			    edges {
			      node{
			        name
			        created_at
			        excerpt
			        slug
			        id
			      }
			    }
			}
		}
	`)
	let d = [];
	query.allStrapiPages.edges.forEach(({node})=>{
		d.push(node);
	})
	d = d[0]
	return (
		<div className={`${styles.flexUnderXS} ${styles.flexDirVerticalXS} ${styles.alignItemsCenterXS} ${styles.mt_2rem} ${styles.mb_6remSM} ${styles.overlayTarget}`}>
			<span className={styles.bgTile}></span>
			<h2 className={`${styles.mb_2rem} ${styles.maxWidth80PercentXS} ${styles.c_mauve}`}>
				{d.name}
			</h2>
			<p className={`${styles.mb_2rem} ${styles.maxWidth80PercentXS}`} dangerouslySetInnerHTML={{__html: d.excerpt}}/>
				
			<p className={`${styles.maxWidth80PercentXS}`}>
				<Link to={`/pages/${d.slug}`}>Learn more.</Link>
			</p>
		</div>
	)
}

export default Q