import React from "react"
import styles from "./styles.module.scss"
import meditationImg from "../../images/home-meditation-rw.png"
import therapyImg from "../../images/home-therapy-rw.png"
import exercisesImg from "../../images/home-exercises-rw.png"
import therapistImg from "../../images/harmonie-dp.png"

import ServiceList from './service-list';
import Message from './message';
import Query from './query'

export default () => <div>
	<div className={`${styles.txtAlignCenter} ${styles.pt_3rem} ${styles.pb_7rem} ${styles.bgColor_lightMauve}`}>
		<div className={`${styles.mb_1rem} ${styles.c_mauve}`}>
			<h3>
				Working to facilitate healing from both physical and/or psychological stressors.
			</h3>
		</div>
		<div>
			<span>
				You're in the right place. We get it.</span>
		</div>
	</div>
	<div className={`${styles.mw_75_percent_sm} ${styles.marginAutoSM}`}>
		<ServiceList styles = {styles}/>
		<Message styles={styles}/>
		<Query styles={styles}/>
	</div>
</div>